const SkillsData = [
    {
        key: "1",
        Name: "Web Design",
        Percentage: "90%",
    },
    {
        key: "2",
        Name: "React.js",
        Percentage: "95%",
    },
    {
        key: "3",
        Name: "Node.js",
        Percentage: "80%",
    },
    {
        key: "4",
        Name: "React Native",
        Percentage: "60%",
    },
    {
        key: "5",
        Name: "Laravel",
        Percentage: "95%",
    },
];
export default SkillsData;
