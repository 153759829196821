import React from "react";

const ConnectionLine = () => {
    return (
        <div className="h-full border-l-2 border-white absolute top-0 mt-6 left-5 rounded-full"></div>
    );
};

const TimelineContainer = ({ Resume }) => {
    const { Icon, Title, Datas } = Resume;
    return (
        <div className="mt-10">
            <div className="flex mb-8 relative">
                <div className="bg-white p-2 mr-4 ml-0.5 rounded-md w-fit h-fit text-black z-10">
                    {Icon}
                </div>
                <div>
                    <p className="font-bold text-2xl">{Title}</p>
                </div>
                <ConnectionLine />
            </div>
            {Datas.map((Data, index) => {
                return (
                    <div className="flex mb-8 relative" key={Data.key}>
                        <div className="p-3 pt-0 mr-4 w-fit h-fit rounded-full z-10">
                            <div className="bg-white p-2 rounded-full ml-[1px]"></div>
                        </div>
                        <div>
                            <p className="font-bold">{Data.SubTitle}</p>
                            <p className="font-medium">{Data.Year}</p>
                            <p className="text-gray-500">{Data.Description}</p>
                        </div>
                        {index + 1 !== Datas.length ? <ConnectionLine /> : null}
                    </div>
                );
            })}
        </div>
    );
};
export default TimelineContainer;
