import React from "react";
import SkillsData from "../../../Data/SkillsData";

const SkillContainer = () => {
    return (
        <>
            {SkillsData.map((Skill, index) => {
                return (
                    <div
                        className={`${index !== 0 ? "mt-5" : ""}`}
                        key={Skill.key}
                    >
                        <p className="font-semibold">
                            {Skill.Name}{" "}
                            <span className="text-gray-400 font-normal">
                                {Skill.Percentage}
                            </span>
                        </p>
                        <div className="progressBar mt-2 w-full bg-white h-3 rounded-lg">
                            <div
                                className={`bg-Primary-Color h-3 rounded-lg`}
                                style={{ width: `${Skill.Percentage}` }}
                            ></div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};
export default SkillContainer;
