import { useState, useEffect } from "react";

const Typewriter = ({ strings, delay, pauseDelay, infinite }) => {
    const [currentText, setCurrentText] = useState("");
    const [currentIndexString, setCurrentIndexString] = useState(0);
    const [currentIndexChar, setCurrentIndexChar] = useState(0);

    useEffect(() => {
        let timeout;
        let pauseTimeout;
        if (currentIndexChar < strings[currentIndexString].length) {
            var text = strings[currentIndexString];
            timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + text[currentIndexChar]);
                setCurrentIndexChar((prevIndex) => prevIndex + 1);
            }, delay);
        } else if (strings.length - 1 > currentIndexString) {
            pauseTimeout = setTimeout(() => {
                setCurrentIndexString((prevIndex) => prevIndex + 1);
                setCurrentText("");
                setCurrentIndexChar(0);
            }, pauseDelay);
        } else if (infinite) {
            pauseTimeout = setTimeout(() => {
                setCurrentIndexString(0);
                setCurrentText("");
                setCurrentIndexChar(0);
            }, pauseDelay);
        }

        return () => {
            clearTimeout(timeout);
            clearTimeout(pauseTimeout);
        };
    }, [
        currentIndexString,
        currentIndexChar,
        delay,
        pauseDelay,
        infinite,
        strings,
    ]);

    return <span className="text-s">{currentText}</span>;
};

export default Typewriter;
