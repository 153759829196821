import React from "react";

function Badge({ text, bgColor, style }) {
    return (
        <div
            className={`inline-block text-xs py-0.5 px-3 rounded-full ${bgColor} text-white ${style}`}
        >
            {text}
        </div>
    );
}

export default Badge;
