import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SideProfile from "./SideProfile/SideProfile";
import Content from "./MainContent/Content";
// import Globe from "./Common/Globe";

function App() {
    return (
        <div className="App bg-gradient-light dark:bg-gradient-dark h-[100vh]">
            {/* <Globe /> */}
            <div className="container justify-center px-4 mx-auto py-12 lg:flex">
                <SideProfile />
                <Content />
            </div>
            <ToastContainer />
        </div>
    );
}

export default App;
