import React from "react";

import InformationsData from "../../Data/InformationsData";

const Information = () => {
    return (
        <div className="Contact lg:block md:grid grid-cols-2 gap-4">
            {InformationsData.map((Contact) => {
                return (
                    <div className="flex items-center py-4" key={Contact.key}>
                        <div className="bg-sky-900 text-white p-2 rounded-md">
                            {Contact.Icon}
                        </div>
                        <div className="ml-5 overflow-hidden text-left">
                            <p className="text-gray-400">{Contact.Name}</p>
                            <a
                                href={Contact.Link}
                                rel="noreferrer"
                                target="_blank"
                                className="text-white text-ellipsis hover:text-slate-300 whitespace-nowrap overflow-hidden"
                                style={{
                                    display: "inline-block",
                                    width: "100%",
                                }}
                            >
                                {Contact.Value}
                            </a>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Information;
