import {
    RiMacLine,
    RiCodeSSlashFill,
    RiSmartphoneLine,
    RiDatabase2Line,
} from "react-icons/ri";
const AboutData = {
    Descriptions: [
        {
            key: "1",
            text: "Hey there! I'm Natanael Geraldo, a fresh graduate from Bina Nusantara University with a degree in Computer Science. I've spent my academic journey diving into the world of software development, mastering languages like C, Java, Python, PHP, and JavaScript. I'm passionate about full-stack development, crafting smooth user experiences with React and building robust back-end systems with Node.js.",
        },
        {
            key: "2",
            text: "Beyond the code, I love working in teams and brainstorming creative solutions. Check out my projects to see what I've been up to. Let's connect if you're up for collaborating on something exciting!",
        },
    ],
    Doings: [
        {
            key: "1",
            Icon: <RiMacLine size={30} />,
            Title: "Web Design",
            Description: "Figma",
        },
        {
            key: "2",
            Icon: <RiCodeSSlashFill size={30} />,
            Title: "Frontend Development",
            Description: "React.js, and Laravel",
        },
        {
            key: "3",
            Icon: <RiSmartphoneLine size={30} />,
            Title: "Mobile Apps",
            Description: "React Native",
        },
        {
            key: "4",
            Icon: <RiDatabase2Line size={30} />,
            Title: "Backend Development",
            Description: "Node.js, Express.js, Laravel, Java, and Python",
        },
    ],
};
export default AboutData;
