import FEE from "../Assets/Portofolio/Codecademy Front-End Engineer.png";
import SQL from "../Assets/Portofolio/Codecademy Learn SQL Course.png";
import cleanData from "../Assets/Portofolio/Codecademy How to Clean Data with Python Course.png";
import ReactEssential from "../Assets/Portofolio/React Js Web Development - The Essentials Bootcamp.jpg";
import ReactNative from "../Assets/Portofolio/React Native - The Practical Guide [2024].jpg";
import Nodejs from "../Assets/Portofolio/NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno).jpg";

const CertificationsData = [
    {
        id: 1,
        name: "Front-End Engineer",
        organization: "Codecademy",
        dateAwarded: "January 2024",
        expiration: "No Expiration",
        category: "Web Development",
        description:
            "The Codecademy Front-End Engineer Path teaches HTML, CSS, JavaScript, React, and Git through hands-on projects, preparing learners for entry-level front-end development roles.",
        certificateImage: FEE,
    },
    {
        id: 4,
        name: "React JS Web Development - The Essentials Bootcamp",
        organization: "Udemy",
        dateAwarded: "April 2023",
        expiration: "No Expiration",
        category: "Web Development",
        description:
            "The React JS Essentials Bootcamp on Udemy covers key React and Redux skills for building interactive web apps, emphasizing components, state, hooks, and Redux.",
        certificateImage: ReactEssential,
    },
    {
        id: 6,
        name: "NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)",
        organization: "Udemy",
        dateAwarded: "November 2023",
        expiration: "No Expiration",
        category: "Web Development",
        description:
            "The Node.js Complete Guide on Udemy teaches backend development with Node.js, covering REST APIs, GraphQL, databases, and deployment through hands-on projects.",
        certificateImage: Nodejs,
    },
    {
        id: 5,
        name: "React Native - The Practical Guide [2024]",
        organization: "Codecademy",
        dateAwarded: "May 2023",
        expiration: "No Expiration",
        category: "Mobile Programming",
        description:
            "The React Native Practical Guide on Udemy teaches core skills for building cross-platform mobile apps, covering components, navigation, and device features through projects.",
        certificateImage: ReactNative,
    },
    {
        id: 2,
        name: "Learn SQL Course",
        organization: "Codecademy",
        dateAwarded: "January 2022",
        expiration: "No Expiration",
        category: "Database",
        description:
            "The Codecademy Learn SQL Course teaches SQL basics for querying, filtering, and analyzing data in relational databases.",
        certificateImage: SQL,
    },
    {
        id: 3,
        name: "How to Clean Data with Python",
        organization: "Codecademy",
        dateAwarded: "May 2024",
        expiration: "No Expiration",
        category: "Data Science",
        description:
            "The Codecademy Data Cleaning with Python course covers essential techniques to clean and prepare data using Python and Pandas.",
        certificateImage: cleanData,
    },
];

export default CertificationsData;
