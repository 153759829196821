import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import SectionTitle from "../Common/SectionTitle";
import PortofoliosData from "../../Data/PortofoliosData";
import PortofolioContainer from "./ContainerComponent/PortofolioContainer";
import PortofolioModal from "./ContainerComponent/PortofolioModal";

const Portofolio = () => {
    const [activeCategory, setActiveCategory] = useState("All");
    const [filteredPortofolios, setFilteredPortofolios] =
        useState(PortofoliosData);
    const [popUpModal, setPopUpModal] = useState(false);
    const [popUpContent, setPopUpContent] = useState("");
    const listCategory = [
        "All",
        ...new Set(PortofoliosData.map((item) => item.type).sort()),
    ];

    useEffect(() => {
        setFilteredPortofolios(
            activeCategory.toLowerCase() === "all"
                ? PortofoliosData
                : PortofoliosData.filter((x) => x.type === activeCategory)
        );
    }, [activeCategory]);

    const changeCategory = (event) => {
        setActiveCategory(event.target.value);
    };
    const openPopUp = (id) => () => {
        setPopUpModal(true);
        setPopUpContent(id);
    };
    const closePopUp = () => {
        setPopUpModal(false);
        setPopUpContent("");
    };

    return (
        <>
            <SectionTitle SectionName="Portofolio" />
            <div className="mt-5">
                <label htmlFor="categorySelect" className="block mb-2">
                    Select Category:
                </label>
                <div className="relative">
                    <select
                        id="categorySelect"
                        onChange={changeCategory}
                        className="block appearance-none w-full bg-Third-Color border border-gray-600 hover:border-gray-500 dark:hover:border-gray-400 px-4 py-2 pr-8 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                    >
                        {listCategory.map((category) => (
                            <option value={category} key={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white dark:text-gray-300">
                        <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            id="Bold"
                            viewBox="0 0 24 24"
                            width="512"
                            height="512"
                        >
                            <path d="M19.061,7.854a1.5,1.5,0,0,0-2.122,0l-4.586,4.585a.5.5,0,0,1-.707,0L7.061,7.854A1.5,1.5,0,0,0,4.939,9.975l4.586,4.586a3.5,3.5,0,0,0,4.95,0l4.586-4.586A1.5,1.5,0,0,0,19.061,7.854Z" />
                        </svg>
                    </div>
                </div>
            </div>
            <TransitionGroup className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5">
                {filteredPortofolios.map((filteredPortofolio) => (
                    <CSSTransition
                        key={filteredPortofolio.key}
                        timeout={300}
                        classNames="portfolio"
                    >
                        <PortofolioContainer
                            PortofolioData={filteredPortofolio}
                            openPopUp={openPopUp}
                        />
                    </CSSTransition>
                ))}
            </TransitionGroup>
            {popUpModal && (
                <PortofolioModal id={popUpContent} closePopUp={closePopUp} />
            )}
        </>
    );
};

export default Portofolio;
