import React from "react";
import Badge from "../../Common/Badge";

const PortofolioContainer = ({ PortofolioData, openPopUp }) => {
    const { key, title, description, type, status } = PortofolioData;

    return (
        <div
            className="md:text-left bg-white bg-opacity-10 rounded-2xl backdrop-blur-sm border border-gray-500 md:mt-0 p-3 hover:cursor-pointer"
            onClick={openPopUp(key)}
        >
            <div className="mt-2">
                <span className="bg-gray-300 bg-opacity-20 p-1 rounded-md text-white text-sm px-3">
                    {type}
                </span>
            </div>
            <div className="font-semibold text-xl mt-4">{title}</div>
            <Badge
                text={status ? "Done" : "On Progress"}
                bgColor={status ? "bg-green-700" : "bg-yellow-600"}
            />

            <div className="text-sm text-gray-300 mt-2 line-clamp-4">
                {description}
            </div>
            {/* <ul>
                {technology.map((tech) => {
                    return (
                        <li
                            className="inline-block mr-2 mb-2 hover:-translate-y-0.5 transition-all duration-200 mt-4"
                            key={tech}
                        >
                            <span className="bg-gray-300 bg-opacity-20 p-1 rounded-md text-white text-sm px-3">
                                {tech}
                            </span>
                        </li>
                    );
                })}
            </ul> */}
        </div>
    );
};
export default PortofolioContainer;
