import React from "react";

const SectionTitle = ({ SectionName }) => {
    return (
        <div>
            <p className="text-3xl font-bold">{SectionName}</p>
            <div className="w-12 h-1.5 bg-white mt-2 rounded-3xl"></div>
        </div>
    );
};
export default SectionTitle;
